@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish-Bold.woff2') format('woff2'),
    url('assets/fonts/Mulish-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('assets/fonts/Mulish-Regular.woff2') format('woff2'),
    url('assets/fonts/Mulish-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  height: 100%;
  overflow-y: hidden;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

/* Animate */
.fade-enter {
  opacity: 0.1;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity ease-in-out 600ms;
}

.fade-exit,
.fade-exit.fade-exit-active .fade-exit-done {
  display: none;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
