.styled-select {
    min-width: 42px;
    max-width: 350px;
    width: 100%;
    padding: 0 12px;
    border-radius: 20px;
    border: 1px solid #fff;
    overflow: hidden;
    transition: all linear 0.5s;
  }
  
  .styled-select .ant-select-selection-item {
    display: none;
    transition: opacity 0.3s;
  }
  
  .styled-select .ant-select-selection-item,
  .styled-select .ant-select-selection-search {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    color: #fff;
  }
  
  .styled-select .my-dropdown {
    padding-left: 50px;
  }
  
  .styled-select .ant-select-arrow {
    right: -2px;
    top: 17%;
    color: #fff;
    padding: 21px;
    display: flex;
    font-size: 20px;
    background: #0275d8;
    align-items: center;
    justify-content: center;
  }
  
  @media screen and (max-width: 768px) {
    .styled-select .ant-select-selection-item,
    .styled-select .ant-select-selection-search {
      font-size: 16px;
    }
  }
  
  .styled-select-searching .ant-select-selection-item {
    display: block;
  }